<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto"
          ><h1>{{ isEditing ? "Edit" : "Add a New" }} Product</h1></v-col
        >
      </v-row>
      <v-divider class="mb-6"></v-divider>
      <v-form @submit.prevent="saveProduct" method="post">
        <v-text-field
          label="Name *"
          type="text"
          v-model="fields.name"
          required
          outlined
          :error="errors.hasOwnProperty('name')"
          :error-messages="errors['name']"
        ></v-text-field>
        <v-text-field
          label="Permalink *"
          type="type"
          v-model="permalink"
          required
          outlined
          dense
          :error="errors.hasOwnProperty('permalink')"
          :error-messages="errors['permalink']"
        ></v-text-field>
        <v-card outlined class="mb-8">
          <v-card-title class="grey lighten-4">Basic Details</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-select
              label="Type *"
              v-model="fields.type"
              :items="productTypes"
              item-text="label"
              item-value="value"
              outlined
              dense
              :error="errors.hasOwnProperty('type')"
              :error-messages="errors['type']"
            ></v-select>
            <v-textarea
              label="Description"
              v-model="fields.description"
              hint="This gets displayed on the Front End Website"
              persistent-hint
              outlined
              dense
              class="mb-4"
            ></v-textarea>
            <v-select
              label="Product Accent Colour"
              v-model="fields.colour"
              :items="productColours"
              item-text="label"
              item-value="value"
              outlined
              dense
              :error="errors.hasOwnProperty('colour')"
              :error-messages="errors['colour']"
            ></v-select>
            <v-text-field
              label="Product Background Colour"
              v-model="fields.product_background_colour"
              outlined
              dense
              :error="errors.hasOwnProperty('product_background_colour')"
              :error-messages="errors['product_background_colour']"
            ></v-text-field>
            <v-text-field
              label="GA Code *"
              v-model="fields.ga_code"
              required
              outlined
              dense
              :error="errors.hasOwnProperty('ga_code')"
              :error-messages="errors['ga_code']"
            ></v-text-field>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-8">
          <v-card-title class="grey lighten-4">Pricing</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-card outlined>
                  <v-card-subtitle>12kg Prices</v-card-subtitle>
                  <v-card-text>
                    <v-text-field
                      label="One-Off Order *"
                      type="number"
                      min="0.00"
                      step="1.00"
                      v-model="fields.normal12"
                      prefix="£"
                      dense
                      outlined
                      :error="errors.hasOwnProperty('normal12')"
                      :error-messages="errors['normal12']"
                    ></v-text-field>
                    <v-text-field
                      label="Subscription *"
                      type="number"
                      min="0.00"
                      step="1.00"
                      v-model="fields.subscription12"
                      prefix="£"
                      dense
                      outlined
                      :error="errors.hasOwnProperty('subscription12')"
                      :error-messages="errors['subscription12']"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card outlined>
                  <v-card-subtitle>24kg Prices</v-card-subtitle>
                  <v-card-text>
                    <v-text-field
                      label="One-Off Order *"
                      type="number"
                      min="0.00"
                      step="1.00"
                      v-model="fields.normal24"
                      prefix="£"
                      dense
                      outlined
                      :error="errors.hasOwnProperty('normal24')"
                      :error-messages="errors['normal24']"
                    ></v-text-field>
                    <v-text-field
                      label="Subscription *"
                      type="number"
                      min="0.00"
                      step="1.00"
                      v-model="fields.subscription24"
                      prefix="£"
                      dense
                      outlined
                      :error="errors.hasOwnProperty('subscription24')"
                      :error-messages="errors['subscription24']"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col class="pt-0 pb-0">
            <v-card outlined>
              <v-card-title class="grey lighten-4"
                >Additional Details</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <v-textarea
                  label="Composition"
                  v-model="fields.composition"
                  outlined
                  dense
                ></v-textarea>
                <v-textarea
                  label="Nutritional Additives per kg"
                  v-model="fields.additives"
                  outlined
                  dense
                ></v-textarea>
                <v-textarea
                  label="Analytical Constituents"
                  v-model="fields.constituents"
                  outlined
                  dense
                ></v-textarea>
                <v-textarea
                  label="What makes our superfood great?"
                  v-model="fields.breakdown"
                  outlined
                  dense
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <v-card outlined>
              <v-card-title class="grey lighten-4">Images</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-card v-if="productImages.mainThumbnail" class="mb-6">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="d-flex flex-column justify-space-between">
                      <v-card-title>Main Thumbnail</v-card-title>
                      <v-card-subtitle
                        >Mainly shown on the Recipes page</v-card-subtitle
                      >
                      <v-card-actions>
                        <v-btn
                          text
                          small
                          @click="productImages.mainThumbnail = false"
                          >Change</v-btn
                        >
                      </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="100" tile>
                      <v-img :src="product.asset_urls.main_thumbnail"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-file-input
                  v-else
                  label="Main Thumbnail"
                  v-model="fields.mainThumbnail"
                  :rules="imageRules"
                  accept="image/png, image/jpeg"
                  placeholder="Pick an image"
                  prepend-icon="mdi-image"
                  outlined
                  hint="Mainly shown on the Recipes page"
                  :persistent-hint="true"
                ></v-file-input>

                <v-card v-if="productImages.secondaryThumbnail" class="mb-6">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="d-flex flex-column justify-space-between">
                      <v-card-title>Secondary Thumbnail</v-card-title>
                      <v-card-subtitle
                        >Mainly shown on the Basket & Checkout</v-card-subtitle
                      >
                      <v-card-actions>
                        <v-btn
                          text
                          small
                          @click="productImages.secondaryThumbnail = false"
                          >Change</v-btn
                        >
                      </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="100" tile>
                      <v-img
                        :src="product.asset_urls.secondary_thumbnail"
                      ></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-file-input
                  v-else
                  label="Secondary Thumbnail"
                  v-model="fields.secondaryThumbnail"
                  :rules="imageRules"
                  accept="image/png, image/jpeg"
                  placeholder="Pick an image"
                  prepend-icon="mdi-image"
                  outlined
                  hint="Mainly shown on the Basket & Checkout"
                  :persistent-hint="true"
                ></v-file-input>

                <v-card v-if="productImages.productLabel" class="mb-6">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="d-flex flex-column justify-space-between">
                      <v-card-title>Product Label</v-card-title>
                      <v-card-subtitle
                        >Mainly shown on the Homepage Slider & Grain Free
                        page</v-card-subtitle
                      >
                      <v-card-actions>
                        <v-btn
                          text
                          small
                          @click="productImages.productLabel = false"
                          >Change</v-btn
                        >
                      </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="100" tile>
                      <v-img :src="product.asset_urls.product_label"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-file-input
                  v-else
                  label="Product Label"
                  v-model="fields.productLabel"
                  :rules="imageRules"
                  accept="image/png, image/jpeg"
                  placeholder="Pick an image"
                  prepend-icon="mdi-image"
                  outlined
                  hint="Mainly shown on the Homepage Slider & Grain Free page"
                  :persistent-hint="true"
                ></v-file-input>

                <v-card v-if="productImages.productBag" class="mb-6">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="d-flex flex-column justify-space-between">
                      <v-card-title>Product Bag</v-card-title>
                      <v-card-subtitle
                        >Shown on the product's own recipe page</v-card-subtitle
                      >
                      <v-card-actions>
                        <v-btn
                          text
                          small
                          @click="productImages.productBag = false"
                          >Change</v-btn
                        >
                      </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="100" tile>
                      <v-img :src="product.asset_urls.product_bag"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-file-input
                  v-else
                  label="Product Bag"
                  v-model="fields.productBag"
                  :rules="imageRules"
                  accept="image/png, image/jpeg"
                  placeholder="Pick an image"
                  prepend-icon="mdi-image"
                  outlined
                  hint="Shown on the product's own recipe page"
                  :persistent-hint="true"
                ></v-file-input>

                <v-card v-if="productImages.percentageImage" class="mb-6">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="d-flex flex-column justify-space-between">
                      <v-card-title>Percentage Image</v-card-title>
                      <v-card-subtitle
                        >Shown on the product's own recipe page under the
                        description</v-card-subtitle
                      >
                      <v-card-actions>
                        <v-btn
                          text
                          small
                          @click="productImages.percentageImage = false"
                          >Change</v-btn
                        >
                      </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="100" tile>
                      <v-img :src="product.asset_urls.percentage_image"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-file-input
                  v-else
                  label="Percentage Image"
                  v-model="fields.percentageImage"
                  :rules="imageRules"
                  accept="image/png, image/jpeg"
                  placeholder="Pick an image"
                  prepend-icon="mdi-image"
                  outlined
                  hint="Shown on the product's own recipe page under the description"
                  :persistent-hint="true"
                ></v-file-input>

                <v-card v-if="productImages.breakdownImage">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="d-flex flex-column justify-space-between">
                      <v-card-title>Breakdown Image</v-card-title>
                      <v-card-subtitle
                        >Shown on the product's own recipe page under the
                        heading 'What makes our superfood
                        great?'</v-card-subtitle
                      >
                      <v-card-actions>
                        <v-btn
                          text
                          small
                          @click="productImages.breakdownImage = false"
                          >Change</v-btn
                        >
                      </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="100" tile>
                      <v-img :src="product.asset_urls.breakdown_image"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-file-input
                  v-else
                  label="Ingredients Breakdown"
                  v-model="fields.breakdownImage"
                  :rules="imageRules"
                  accept="image/png, image/jpeg"
                  placeholder="Pick an image"
                  prepend-icon="mdi-image"
                  outlined
                  hint="Shown on the product's own recipe page under the heading 'What makes our superfood great?'"
                  :persistent-hint="true"
                ></v-file-input>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              fixed
              tile
              ripple
              bottom
              right
              color="accent"
              x-large
              v-on="on"
              width="150px"
              type="submit"
              :loading="loading"
              ><v-icon left>mdi-content-save</v-icon> Save</v-btn
            >
          </template>
          <span>Save Product</span>
        </v-tooltip>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { paw } from "../../mixins/";

export default {
  mixins: [paw],

  props: {
    isEditing: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: { name: "module-paw-products" },
        },
      ],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2MB!",
      ],
      fields: {
        name: null,
        permalink: null,
        description: null,
        type: null,
        colour: null,
        ga_code: null,
        normal12: null,
        normal24: null,
        subscription12: null,
        subscription24: null,
        composition: null,
        additives: null,
        constituents: null,
        breakdown: null,
        mainThumbnail: null,
        secondaryThumbnail: null,
        productLabel: null,
        productBag: null,
        percentageImage: null,
        breakdownImage: null,
        product_background_colour: null,
      },
      productImages: {
        mainThumbnail: false,
        secondaryThumbnail: false,
        productLabel: false,
        productBag: false,
        percentageImage: false,
        breakdownImage: false,
      },
      productTypes: [
        { value: "adult", label: "Adult" },
        { value: "puppy", label: "Puppy" },
      ],
      productColours: [
        { value: "red", label: "Red" },
        { value: "green", label: "Green" },
        { value: "yellow", label: "Yellow" },
        { value: "blue", label: "Blue" },
      ],
      loading: false,
      product: {},
      errors: {},
    };
  },

  created() {
    if (this.isEditing) {
      const product = this.$store.state.paw["product"];
      this.product = product;
      this.fields.name = product.name;
      this.fields.permalink = product.permalink;
      this.fields.description = product.description;
      this.fields.type = product.type;
      this.fields.colour = product.colour;
      this.fields.ga_code = product.ga_code;
      this.fields.product_background_colour = product.product_background_colour;
      this.fields.normal12 = this.getProductPrice(product, 12, "price_normal");
      this.fields.normal24 = this.getProductPrice(product, 24, "price_normal");
      this.fields.subscription12 = this.getProductPrice(
        product,
        12,
        "price_subscription"
      );
      this.fields.subscription24 = this.getProductPrice(
        product,
        24,
        "price_subscription"
      );
      this.fields.composition = product.composition;
      this.fields.additives = product.additives;
      this.fields.constituents = product.constituents;
      this.fields.breakdown = product.breakdown;
      this.productImages.mainThumbnail = product.main_thumbnail ? true : false;
      this.productImages.secondaryThumbnail = product.secondary_thumbnail
        ? true
        : false;
      this.productImages.productLabel = product.product_label ? true : false;
      this.productImages.productBag = product.product_bag ? true : false;
      this.productImages.percentageImage = product.percentage_image
        ? true
        : false;
      this.productImages.breakdownImage = product.breakdown_image
        ? true
        : false;
      this.breadcrumbs.push({
        text: "Edit",
        disabled: true,
      });
    } else {
      this.breadcrumbs.push({
        text: "Create",
        disabled: true,
      });
    }
  },

  computed: {
    permalink: {
      get() {
        if (this.isEditing) {
          return this.fields.permalink;
        }

        let permalink = this.fields.name;

        if (permalink === "" || permalink === null) {
          return permalink;
        }

        permalink = permalink.trim();
        permalink = permalink.replaceAll("&", "and");
        permalink = permalink.replaceAll(",", "");
        permalink = permalink.replaceAll(/[^a-zA-Z ]/g, "");
        permalink = permalink.replaceAll(" ", "-");
        permalink = permalink.toLowerCase();
        if (permalink.substr(-1) === "-") {
          permalink = permalink.substr(0, permalink.length - 1);
        }

        return permalink;
      },
      set(value) {
        return value;
      },
    },
  },

  methods: {
    saveProduct: function () {
      const appId = this.$route.params.id;

      this.loading = true;
      this.errors = {};

      let fields = this.fields;
      fields.permalink = this.permalink;

      let payload = {
        appId,
        ...fields,
        isEditing: this.isEditing,
      };

      if (this.isEditing) {
        payload.productId = this.product.id;
        payload.mainThumbnailChange = !this.productImages.mainThumbnail;
        payload.secondaryThumbnailChange =
          !this.productImages.secondaryThumbnail;
        payload.productLabelChange = !this.productImages.productLabel;
        payload.productBagChange = !this.productImages.productBag;
        payload.percentageImageChange = !this.productImages.percentageImage;
        payload.breakdownImageChange = !this.productImages.breakdownImage;
      }

      this.$store
        .dispatch("paw/saveProduct", {
          ...payload,
        })
        .then(() => {
          this.$router.push({ name: "module-paw-products", params: { appId } });
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
    getImage: function (src) {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${src}`;
    },
  },
};
</script>
